(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.line')
    .controller('RefereeLine', RefereeLine);

  function  RefereeLine($mdToast, $scope, $state,  AclService, refereeLicense, $mdDialog, $filter, RefereeLicence, refId,lineId, lineread,InvoiceRef,InvoiceRefLine,Receptores) {
   var vm = this;
    vm.ctrlName = 'RefereeLine';
  
      $scope.receptores=Receptores;
 $scope.sel=1;
    vm.person=refereeLicense.person;

  var linea={
       idref: refId,
      fecha:'' ,
      esport:'',
      numpartits: 0,
      preu:0,sinirpf:0,sintexto:0,idreceptor:1
            };
     $scope.fecha=lineread.fecha;
     $scope.esport=lineread.esport;
     $scope.numpartits=lineread.numpartits;
     $scope.preu=lineread.preu;
     $scope.numinvoice=lineread.numinvoice;
     $scope.selectreceptor=lineread.idreceptor;
     $scope.sel=lineread.idreceptor;
       if (lineread.sinirpf==='1'){
          $scope.sinirpf=true;
       }
       
          if (lineread.sintexto==='1'){
          $scope.sintexto=true;
       }
  
      vm.createLinea = function () {
          var sir=0;
          if($scope.sinirpf){
              sir=1;
          }
           var sirt=0;
          if($scope.sintexto){
              sirt=1;
          }
      linea = {
      id:lineId,
      idref: refId,
      fecha:parseDate( $scope.fecha),
      esport: $scope.esport,
      numpartits:  $scope.numpartits,
      preu:$scope.preu, sinirpf:sir,sintexto:sirt,idreceptor:$scope.selectreceptor.idReceptor
      
        };
    
     InvoiceRef.update({Id: refId},linea);
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('Linia creada'))
          .position('bottom left')
          .hideDelay(6000)
      );   
      
     setTimeout( function() {$state.go('home.refereeNewInvoice', {licenseId: refId}, {reload: true});},4000);
     
      };
      
   function parseDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }
    
     vm.esborrar=function(){
        var ret= confirm("Segur que vol esborrar aquesta linea?");
        if (ret) {InvoiceRefLine.delete({lineId: lineId});  
              $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('Linia esborrada'))
          .position('bottom left')
          .hideDelay(6000)
      );   
      
     setTimeout( function() {$state.go('home.refereeNewInvoice', {licenseId: refId}, {reload: true});},4000);
        }
     };
  }
}());




